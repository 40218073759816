import React, { ReactElement, useEffect, ReactNode } from 'react'
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'
import { useStore } from '~/dataStore'
import CampaignDetails from '~/pages/Campaign/CampaignDetails/CampaignDetails'
import { withStore } from './dataStore'
import CampaignBuilder from '~/pages/CampaignBuilder/CampaignBuiler'
import NotificationBuilder from '~/pages/NotificationBuilder/NotificationBuilder'
import { NotificationBuilderProviderWithStore } from '~/pages/NotificationBuilder/NotificationBuilder.context'
import NewCampaign from '~/pages/Campaign/NewCampaign/NewCampaign'
import CampaignList from '~/pages/Campaign/CampaignsList'
import AppDashboard from '~/pages/App/Dashboard'
import DevicesDashboard from '~/pages/DevicesDashboard'
import CampaignsDashboard from '~/pages/Campaign/CampaignsDashboard'
import { CampaignsDashboardProvider } from '~/pages/Campaign/CampaignsDashboard/CampaignDashboard.context'
import Geofences from '~/pages/Geofences'
import { GeofencesProvider } from '~/pages/Geofences/Geofences.context'
import BeaconsList from '~/pages/Beacons'
import AppChannelSettings from '~/pages/App/ChannelSettings'
import AppSettings from '~/pages/App/Settings'
import CampaignReport from '~/pages/Campaign/CampaignReports/CampaignReport'
import SegmentsList from '~/pages/Segment/SegmentsList'
import Users from '~/pages/Users'
import UserDetails from '~/pages/Users/UserDetails'
import SegmentUsersList from '~/pages/Segment/SegmentUsersList'
import NewApplication from '~/pages/App/NewApplication'
import routes, { RoutesKey } from './routes'
import { IAppDetails, IFeatureFlags } from './dataStore/App.interface'
import Journeys from '~/pages/Journeys'
import CampaignReportList from './pages/Campaign/CampaignReports/CampaignReportList'
import SegmentBuilder from './pages/Segment/SegmentsBuilder/SegmentsBuilder'
import Dashboard from './pages/Dashboard/Dashboard'
import Messages from '~/pages/Messages/Messages'
import Feed from './pages/Feed/Feed'
import OpportunitiesDashboard from '~/pages/OpportunitiesDashboard'

const routeComponent: { [key in RoutesKey]: ReactNode } = {
  appDashboard: <AppDashboard />,
  dashboard: <Dashboard />,
  appSettings: <AppSettings />,
  appChannelSettings: <AppChannelSettings />,
  devicesDashboard: <DevicesDashboard />,
  campaignDashboard: (
    <CampaignsDashboardProvider>
      <CampaignsDashboard />
    </CampaignsDashboardProvider>
  ),
  opportunitiesDashboard: <OpportunitiesDashboard />,
  campaignReports: <CampaignReportList />,
  campaignReport: <CampaignReport />,
  campaignDetails: <CampaignDetails />,
  campaignList: <CampaignList />,
  segmentsList: <SegmentsList />,
  segmentsBuilder: <SegmentBuilder />,
  userList: <Users />,
  userProfile: <UserDetails />,
  segmentUsersList: <SegmentUsersList />,
  beaconsList: <BeaconsList />,
  geofences: (
    <GeofencesProvider>
      <Geofences />
    </GeofencesProvider>
  ),
  newCampaign: <NewCampaign />,
  campaignBuilder: <CampaignBuilder />,
  alertCampaignBuilder: (
    <NotificationBuilderProviderWithStore>
      <NotificationBuilder />
    </NotificationBuilderProviderWithStore>
  ),
  newApp: <NewApplication />,
  journeys: <Journeys />,
  messages: <Messages />,
  feed: <Feed />
}

export function ScrollToTop(): null {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export function PrivateRoute({
  children,
  appDetails,
  appId,
  flag
}: React.PropsWithChildren<{
  appDetails: IAppDetails
  appId: string
  flag: keyof IFeatureFlags
}>): React.ReactElement | null {
  if (!appDetails.id) {
    return null
  }

  return appDetails.featureFlags[flag] === true ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: generatePath(routes.appDashboard.path, { appId })
      }}
    />
  )
}

const Router = withStore(function Router(): ReactElement | null {
  const store = useStore()

  return (
    <Switch>
      {Object.entries<{
        path: string
        flag?: keyof IFeatureFlags
        withSubRoute?: boolean
        subRoute?: boolean
      }>(routes)
        .filter(([key, value]) => !('subRoute' in value))
        .map(([key, value]) =>
          value.flag ? (
            <Route path={value.path} key={key}>
              <PrivateRoute
                appDetails={store.app.appDetails}
                appId={store.app.currentApp.id}
                flag={value.flag}>
                {routeComponent[key as RoutesKey]}
              </PrivateRoute>
            </Route>
          ) : (
            <Route
              key={key}
              exact={!value.withSubRoute}
              path={value.path}
              render={() => {
                return routeComponent[key as RoutesKey]
              }}
            />
          )
        )}
    </Switch>
  )
})

export default Router
